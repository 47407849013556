<template>
  <div class="explore-content">
    <IconTV />
    <a href="https://preowned.aamotors.com/">{{ t.home }}</a>
    <RouterLink to="/search">{{ languageResources?.Common?.search_cars }}</RouterLink>
    <a href="https://www.aamotors.com/our_businesses/cpov">{{ languageResources?.Common?.about }}</a>
    <a href="/#frequently-asked-questions">{{ languageResources?.Common?.faq }}</a>
    <a href="/contact">{{ languageResources?.Common?.contact }}</a>
  </div>
</template>

<script>
import IconTV from '@/assets/icons/tv.svg'
import { langMixin } from '@/lang'
import { RouterLink } from 'vue-router'

export default {
  name: 'OtherContent',
  components: { IconTV, RouterLink },
  mixins: [langMixin('Home')]
}
</script>

<style lang="scss">
.explore-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  a {
    text-decoration: none;
    font-weight: 500;
  }
}
</style>
