<template>
  <div class="phone-box">
    <IconPhone />
    <div>
      <span>{{ t.callUsAt }}</span>
      <a :href="`tel:${t.phone}`">{{ t.phone }}</a>
    </div>
  </div>
  <div class="other-content">
    <IconMail />
    <div>
      <span>{{ t.emailUsAt }}</span>
      <a :href="`mailTo:${t.forCustomerEmail}`">{{ t.forCustomerEmail }}</a>
    </div>
  </div>
</template>

<script>
import IconMail from '@/assets/icons/mail.svg'
import { langMixin } from '@/lang'
import IconPhone from '@/assets/icons/phone.svg'

export default {
  name: 'OtherContent',
  components: { IconMail, IconPhone },
  mixins: [langMixin('Home')]
}
</script>

<style lang="scss">
.phone-box {
  margin-bottom: 1.5rem;

  @media screen and (max-width: 36rem) {
    margin-bottom: 0;
  }
}

.other-content,
.phone-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  > div {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  span {
    font-weight: 500;
    line-height: 1.5rem;
    min-width: max-content;
  }

  a {
    text-decoration: underline;
    word-break: break-all;
  }
}
</style>
